export default function Custom404() {
    return (
        <div className="container-fluid justify-content-center">
            <div className="d-flex flex-column flex-lg-row page404 justify-content-center">

                <div className="img404 d-flex">
                    <img src="/home/404.svg" alt="404"/>
                </div>
                
                <h1>404</h1>

                <h2>página não<br />encontrada</h2>

            </div>

        </div>
    )
  }